<template>
  <div class="footer flex flex-wrap justify-center py-2">
    <div v-for="item in items" :key="item.text" class="flex">
      <tml-anchor
        v-if="item.href"
        :href="item.href"
        class="small link cursor-pointer"
        :text-color="theme().get('text.color')"
        :text="item.text"
      />

      <tml-anchor
        v-else
        class="small link cursor-pointer"
        :text-color="theme().get('text.color')"
        :text="item.text"
        @click="item.onClick"
      />
      <span
        v-if="last(items) !== item"
        class="small px-1"
        :style="{color: theme().get('text.color')}"
      >
        ·
      </span>
    </div>
  </div>
</template>

<script>
import {maxDevice} from '@teemill/common/helpers';
import {last} from 'lodash';
import {useProjectStore} from '../stores/project';

export default {
  name: 'TeemillFooter',

  inject: ['theme'],

  computed: {
    items() {
      const items = [
        {
          text: 'Privacy',
          href: '/privacy-policy/',
        },
        {
          text: 'Terms',
          href: '/terms-and-conditions/',
        },
        {
          text: 'Sitemap',
          href: '/sitemap/',
        },
        {
          text: 'Careers',
          href: 'https://careers.teemill.com/',
        },
        {
          text: 'Affiliates',
          href: '/affiliate-marketing-program-uk-sustainability/',
        },
        {
          text: 'API',
          href: '/api-docs/',
        },
        {
          text: 'Company Number: 7071956',
          href: '#',
        },
      ];

      if (this.$store.state.user) {
        items.push({
          text: 'Feedback',
          onClick: this.openFeedbackPopup,
        });
      }

      return items;
    },
  },

  methods: {
    last,
    maxDevice,

    openFeedbackPopup() {
      this.$overlays.open('feedback-popup');
    },
  },
};
</script>
