<script lang="ts">
export default {
  name: 'Dashboard',
};
</script>

<script setup lang="ts">
import {
  provide,
  reactive,
  ref,
  watch,
  onMounted,
  onUnmounted,
  defineAsyncComponent,
  computed,
} from 'vue';
import {useRouter, useRoute} from 'vue-router';

import {useDocumentVisibility, watchDebounced} from '@vueuse/core';

import {formatUrl, PausableRouter, isTabActive} from '@teemill/common/helpers';
import {Page, PageLike} from '@teemill/modules/page-framework';
import {
  AppBase,
  AppHeader,
  AppSidebar,
  App,
  usePwaRefresh,
} from '@teemill/modules/app';
import {overlays} from '@teemill/modules/overlays';
import {
  useLiveChat,
  liveChatCustomerIdentityProvider,
} from '@teemill/modules/live-chat';

import Cookie from 'js-cookie';
import TeemillHeaderLogo from './components/TeemillHeaderLogo.vue';
import TeemillFooter from './components/TeemillFooter.vue';

import {ApiError} from '@teemill/common/errors';
import {http, events, useGtm, usePush} from '@teemill/common/services';
import {validator} from '@teemill/common/plugins';
import {Customer} from '@teemill/common/models';
import {TmlStorage} from '@teemill/common/classes';
import {store} from './services';
import * as layouts from './layouts';
import {useProjectStore} from './stores/project';

import {Theme, themes} from '@teemill/modules/theme-builder';

// Overlays
import {
  TmlDynamicOverlayContainer,
  TmlFeedbackPopup,
} from '@teemill/modules/overlays';
import {ProUpgradePopup, ProSubscribePopup} from '@teemill/modules/pro';
import {
  useNotifications,
  NotificationContainer,
  NotificationPosition,
  NotificationShove,
} from '@teemill/modules/notifications';
import {UpdateUsernamePopup} from '@teemill/modules/chat';
import {RemillReturnPopup} from '@teemill/modules/remill';
import {MandatoryTwoFactorAuthPopup} from '@teemill/modules/two-factor-auth';
import {TmlCookiePopup} from '@teemill/modules/overlays/components/popups';
import {EventBus} from '@teemill/common/classes';

const TeemillHeaderContent = defineAsyncComponent(
  () => import('./components/TeemillHeaderContent.vue')
);
const TeemillOnePagerHeaderContent = defineAsyncComponent(
  () => import('./components/TeemillOnePagerHeaderContent.vue')
);
const TeemillOnePagerSidebarContent = defineAsyncComponent(
  () => import('./components/TeemillOnePagerSidebarContent.vue')
);
const TeemillNavBar = defineAsyncComponent(
  () => import('./components/TeemillNavBar.vue')
);
const TeemillMenu = defineAsyncComponent(
  () => import('./components/TeemillMenu.vue')
);

const router = useRouter() as PausableRouter;
const route = useRoute();
const app = ref<App>(App.get('teemill'));

const notifications = useNotifications();
const push = usePush();

const user = () => store.state.user;
const auth = () => store.state.auth;
const division = () => store.state.store.active;
const projectStore = useProjectStore();
const liveChat = useLiveChat();

store.commit(
  'darkMode/initialiseDarkMode',
  TmlStorage.local.get('darkMode') === 'true'
);

provide('user', user);
provide('division', division);

router.pause();

usePwaRefresh(router);

let initialStateUrl = '/omnis/v3/initial-state/teemill/';

const pageQueryParams = new URLSearchParams(window.location.search);

const projectQueryParam = pageQueryParams.get('project');
if (pageQueryParams.get('operatorMode') === 'headless' && projectQueryParam) {
  initialStateUrl += `?operator=${projectQueryParam}`;
}

fetch(formatUrl(initialStateUrl), {
  credentials: 'include',
  mode: 'no-cors',
})
  .then(response => {
    if (response.ok) {
      return response.json();
    }

    throw new ApiError();
  })
  .then(({manufacturers, pages, stores, user, divisionLogo, splitTest}) => {
    store
      .dispatch('insertInitialState', {
        manufacturers,
        pages,
        stores,
        user,
        divisionLogo,
        splitTest,
      })
      .then(() => {
        pages.forEach((p: PageLike) => {
          app.value.addPage(reactive(new Page(p)).setMode(app.value.mode));
        });

        app.value.pages.forEach(page => router.addRoute(page.routeDef));

        router.resume();
      });
  });

let checkAuthCooldown = false;

const checkAuth = () => {
  if (checkAuthCooldown || !store.state.user) {
    return;
  }

  checkAuthCooldown = true;

  http
    .get(formatUrl('/omnis/v3/initial-state/teemill'))
    .handle(401, () => {
      Customer.logout();
    })
    .success(({user}: {user: any}) => {
      if (!user) {
        Customer.logout();
      }
    })
    .finally(() => {
      setTimeout(() => {
        checkAuthCooldown = false;
      }, 15000);
    });
};

store.dispatch('cart/getResource', {
  name: 'teemill',
});

const isProIncludingIntegrations = computed<boolean>(() => {
  return (
    (projectStore?.active?.integrations &&
      projectStore?.active?.integrations?.length > 0 &&
      !projectStore?.active?.hasIntegration('website')) ||
    !!projectStore?.active?.isPro
  );
});

const subscribeToPush = () => {
  if (push.isSupported() && store.state.user) {
    push.subscribe({
      identifier: store.state.user.id,
      type: 'customer',
    });
  } else {
    overlays.pushNotSupported();
  }
};

events.on('anchor-subscribe-to-notifications', subscribeToPush);

setInterval(() => {
  // Only poll for notifications if we have an active store
  // owner, and if the browser tab is active
  if (store?.state.user && isTabActive()) {
    store?.dispatch('notifications/meta');
  }
}, 30000); // 30s

watchDebounced(
  () => projectStore.active,
  project => {
    liveChat.init({
      customerData: project && {
        name: project.name,
        sessionVariables: {
          isPro: project.isPro,
          manufacturerId: project.id,
          integration: project.integrationType,
        },
      },
      identityProvider: project && liveChatCustomerIdentityProvider,
    });
  },
  {
    immediate: true,
    debounce: 3000,
  }
);

watch(
  () => ({project: projectStore.active, app: app.value}),
  ({app, project}) => {
    if (!app) {
      return;
    }

    if (project) {
      store?.dispatch('notifications/meta');
    }

    if (project?.isPro) {
      app.setTheme(
        store.state.darkMode.isDark ? themes.teemillProDark : themes.teemillPro
      );
    } else {
      app.setTheme(
        store.state.darkMode.isDark ? themes.teemillDark : themes.teemill
      );
    }
  },
  {immediate: true}
);

watch(
  () => route,
  (to, from) => {
    if (to.meta?.title) {
      document.title = `${to.meta.title} | Teemill`;
    }
    liveChat.dismissNotification();
  },
  {immediate: true, deep: true}
);

watch(
  () => route.path.replace(/\/+$/, ''),
  () => {
    window.scrollTo(0, 0);
    checkAuth();
  },
  {immediate: true}
);

watch(useDocumentVisibility(), (current, previous) => {
  if (current === 'visible' && previous === 'hidden') {
    checkAuth();
  }
});

const setAffiliateCookieFromUrlQuery = () => {
  const affCode = route?.query?.aff as string;

  if (
    affCode &&
    Object.keys(validator.validate(affCode, {alpha_dash: true})).length === 0
  ) {
    Cookie.set('teemill-sign-up-affiliate', affCode);
  }
};

const operatorMode = ref('default');

onMounted(async () => {
  await router.isReady();

  TmlStorage.session.set('operatorMode', route.query.operatorMode || 'default');

  operatorMode.value = TmlStorage.session.get('operatorMode') ?? 'default';

  if (operatorMode.value === 'headless') {
    router.beforeEach((to, from, next) => {
      if (to.path !== from.path) {
        if (from.name && to.name) {
          // In this case, we're navigating between pages where the page is actually
          // tabs within the same page. We don't want to send a headless-navigation
          // event in this case.
          if (
            (from.name as string).includes('teemail.campaigns.show.') &&
            (to.name as string).includes('teemail.campaigns.show.')
          ) {
            return next();
          }
        }

        let path = to.path;

        if (to.meta.cloudRedirect) {
          if (typeof to.meta.cloudRedirect === 'string') {
            path = to.meta.cloudRedirect;
          } else {
            // @ts-expect-error Requires improved route meta typings
            path = to.meta.cloudRedirect(to);
          }
        }

        window.parent.postMessage(
          {
            event: 'headless-navigation',
            data: {
              from: from.path,
              to: path,
            },
          },
          '*'
        );

        return next(false);
      }

      next();
    });
  }

  setAffiliateCookieFromUrlQuery();
});

onUnmounted(() => {
  events.off('anchor-subscribe-to-notifications', subscribeToPush);
});

provide('operatorMode', operatorMode);

const onSetTheme = (theme: Theme) => {
  if (app.value !== undefined) {
    app.value.setTheme(theme);
  }
};

const useOnePagerLayout = (layoutType: string) =>
  layoutType === 'onePager' ||
  (!store?.state.user && ['standard', 'tool'].includes(layoutType));

const gtm = useGtm();

if (TmlStorage.local.get('cookies-accepted')) {
  window.gtag('consent', 'update', {
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    ad_storage: 'granted',
    analytics_storage: 'granted',
  });
}

setTimeout(() => {
  gtm.enable();
}, 5000);

const onConsentGranted = () => {
  gtm.trackEvent({event: 'TmlConsentGrantedEvent'});
};
</script>

<template>
  <app-base v-if="app" :app="(app as App)" :layouts="layouts">
    <template #announcement />
    <template #header="{type}">
      <app-header>
        <template #logo>
          <teemill-header-logo class="py-2 mr-4" />
        </template>
        <template #content>
          <teemill-one-pager-header-content v-if="useOnePagerLayout(type)" />
          <teemill-header-content v-else />
        </template>
      </app-header>
    </template>
    <template #sidebar="{type}">
      <app-sidebar>
        <teemill-one-pager-sidebar-content
          v-if="useOnePagerLayout(type)"
          class="p-4"
        />
        <teemill-menu v-else />
      </app-sidebar>
    </template>
    <template #before-content>
      <tml-breadcrumb
        v-if="
          operatorMode === 'default' &&
          $route.meta.breadcrumb &&
          // @ts-expect-error Requires improved route meta typings
          $route.meta.breadcrumb.position !== 'custom'
        "
        class="py-2 px-4"
      />
    </template>
    <template #content>
      <router-view />
    </template>
    <template #after-content />
    <template #footer />
    <template v-if="!projectStore.active?.isTurbo" #subfooter>
      <teemill-footer />
    </template>
    <template #nav-bar="{type}">
      <teemill-nav-bar v-if="!useOnePagerLayout(type)" />
    </template>
    <template #global="{type, options}">
      <!-- Containers -->
      <tml-snackbar-container />
      <notification-container
        v-if="app.isNative"
        class="z-notification"
        :queue="notifications.positions.value[NotificationPosition.TOPRIGHT]"
        :style="{
          position: 'fixed',
          top: `calc(${options.header.height} + 1rem)`,
          right: '1rem',
        }"
      />

      <!-- Popups -->
      <tml-feedback-popup />
      <tml-notifications-popup />
      <update-username-popup :user="user" />
      <tml-dynamic-overlay-container />
      <pro-upgrade-popup />
      <pro-subscribe-popup
        context="subscribe"
        :is-pro="isProIncludingIntegrations"
      />
      <remill-return-popup />
      <mandatory-two-factor-auth-popup
        :force2fa="!!projectStore?.active?.force2fa"
        :user="user()"
        :auth="auth()"
      />
      <tml-cookie-popup
        v-if="router.currentRoute.value.name !== 'cookie-policy' && app.theme"
        @consent-granted="onConsentGranted"
      />
    </template>
  </app-base>
</template>

<style lang="scss">
@import '@teemill/common/assets/scss/core.scss';
</style>
