import 'vue-router';
import {RouterHistory} from 'vue-router';

import {createPausableRouter} from '@teemill/common/helpers';

import * as routes from '../pages';
import {useProjectStore} from '../stores/project';

export const createRouter = (history: RouterHistory) => {
  const router = createPausableRouter({
    history,
    routes: Object.values(routes).flat(),
  });

  router.beforeEach((to, from, next) => {
    if (to.name !== 'dashboard') {
      next();
      return;
    }

    const project = useProjectStore().active;
    if (project?.hasIntegration('api')) {
      next('/api-dashboard/');
    } else {
      next();
    }
  });

  return router;
};
